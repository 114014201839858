
import { _Client } from '@/api-client';
import { AddNonExaminingDateRequest } from '@/api-client/client';
import { Form, message } from 'ant-design-vue';
import { defineComponent, reactive, ref } from 'vue'
import { CalendarOutlined } from "@ant-design/icons-vue";
import dayjs from 'dayjs';

const useForm = Form.useForm;
export default defineComponent({
    components: {
        CalendarOutlined
    },
    props: {
        close: {
            type: Function,
            required: true
        }
    },
    setup(props) {
        const close = (refresh: boolean) => props.close(refresh);
        const loading = ref(false)
        const input = ref(AddNonExaminingDateRequest.fromJS({ isActive: true, dates: [] }))
        const showDatePicker = ref(false)

        const dateChange = (value: any) => {
            var index = input.value.dates.findIndex((x) => x == value.format("DD/MM/YYYY"))
            if (index == -1) {
                input.value.dates.push(value.format("DD/MM/YYYY"));
            }
            else {
                input.value.dates.splice(index, 1)
            }

        };

        const disabledDate = (val: any) => {
            if (!val) {
                return false;
            }

            //// 历史日期不可选
            if (dayjs().isAfter(val, 'day')) {
                return true;
            }

            return false;
        }

        const selectDate = (val: any) => {
            const date = val.format("DD/MM/YYYY")
            return input.value.dates.some(x => x == date);
        }

        const rules = reactive({
            dates: [
                {
                    required: true,
                    message: 'Please select Non-Examining Date',
                    type: 'array'
                },
            ],
            reason: [
                {
                    required: true,
                    message: 'Please input reason',
                },
            ],
            isActive: [
                {
                    required: true,
                },
            ],
        });

        const { validate, validateInfos } = useForm(input, rules)

        const submit = () => {
            validate().then(() => {
                loading.value = true;
                const requestInput = AddNonExaminingDateRequest.fromJS(input.value);

                requestInput.dates = input.value.dates.map(v => new Date(dayjs(v, 'DD/MM/YYYY').format('YYYY-MM-DD')))
                
                _Client.noneExaminingDateClient.add(requestInput).then(rep => {
                    if (rep.errorDate && rep.errorDate.length > 0) {
                        message.warning(`This date already exists.【${rep.errorDate!.join("，")}】`)
                        return;
                    }

                    close(true)
                    message.success("Operation Success.")
                }).finally(() => {
                    loading.value = false;
                }).catch(_ => { })
            })
        }

        return {
            input,
            loading,
            validateInfos,
            showDatePicker,
            close,
            submit,
            dateChange,
            disabledDate,
            selectDate
        }
    }
})
