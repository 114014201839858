
import { _Client } from '@/api-client';
import { ModifyNonExaminingDateRequest } from '@/api-client/client';
import { Form, message } from 'ant-design-vue';
import { defineComponent, onMounted, reactive, ref } from 'vue'

const useForm = Form.useForm;
export default defineComponent({
    props: {
        id: {
            type: String,
            required: true
        },
        close: {
            type: Function,
            required: true
        }
    },
    setup(props) {
        const close = (refresh: boolean) => props.close(refresh);
        const loading = ref(false)
        const input = ref(new ModifyNonExaminingDateRequest())
        var date = ref('');

        const rules = reactive({
            reason: [
                {
                    required: true,
                    message: 'Please input reason',
                },
            ],
            isActive: [
                {
                    required: true,
                },
            ],
        });

        const { validate, validateInfos } = useForm(input, rules)

        const submit = () => {
            validate().then(() => {
                loading.value = true;
                _Client.noneExaminingDateClient.modify(input.value).then(_ => {
                    message.success("Operation Success.")
                    close(true)
                }).finally(() => {
                    loading.value = false;
                })
            })
        }

        onMounted(() => {
            loading.value = true;
            _Client.noneExaminingDateClient.get(props.id).then(rep => {
                input.value.init(rep)
                date.value = rep.date!
            }).finally(() => loading.value = false)
        })

        return {
            input,
            loading,
            validateInfos,
            date,
            submit,
            close,
        }
    }
})
